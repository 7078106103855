import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { handleHomeButton } from "../helpers";

function SuccessPage() {
  const Navigate = useNavigate();

  function handleHomeButtonClick() {
    handleHomeButton(Navigate);
  }

  return (
    <Box className="center-everything full-width-height">
      <Typography variant="h3">Success</Typography>
      <Typography>Weight has been submitted successfully</Typography>
      <br />
      <Button
        variant="contained"
        endIcon={<HomeIcon />}
        onClick={() => {
          handleHomeButtonClick();
        }}
      >
        Back to Home
      </Button>
    </Box>
  );
}

export default SuccessPage;
